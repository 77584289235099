<template>
  <div>
    <CarRegion :ListCarData=alldatas :listCar="listCar" :reginName="reginName" :getSeachCar="getSeachCar" />
    <CarTypeRegion :pieData=alldatas :reginName="reginName" />
  </div>
</template>

<script>
import CarRegion from "./carRegion.vue";
import CarTypeRegion from "./carTypeOrder.vue";
export default {
  components: {
    CarRegion,
    CarTypeRegion,
  },
  props: {
    alldatas: Object,
    listCar:Array,
    reginName:String,
    getSeachCar:Function
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>