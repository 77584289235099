<template>
  <div>
    <v-chart
      class="chart"
      :option={...zhexian,series:eZheXian}

    />
  </div>
</template>

<script>
import { zhexian } from "./echart";

export default {
  props:{
    eZheXian:Array
  },
  data() {
    return {
      zhexian,
    };
  },
};
</script>

<style>
.chart {
  width: 100%;
  height: 300px;
}
</style>
