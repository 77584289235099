<template>
  <dv-border-box-8
    :reverse="true"
    class="marginBottom10 dv-border-box-8"
    backgroundColor="#0A1C3C"
  >
    <div class="padding20">
      <div class="flex justify-content-space-between">
        <div>车辆信息-{{ reginName }}</div>
        <div>
          <i
            class="el-icon-refresh"
            style="font-size: 24px"
            @click="handleRefresh"
          ></i>
        </div>
      </div>
      <div class="margin10">
        <el-input
          placeholder="请输入车型名称/车辆编号"
          v-model="input3"
          class="search-car-region"
          maxlength="20"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
      </div>

      <div class="list-car-info">
        <div class="list-title">
          <div>车型名称</div>
          <div>车型编号</div>
          <div>当前状态</div>
          <div>行动轨迹</div>
        </div>
        <div class="list-car-box">
          <div
            class="list-car"
            :class="[
              item.state === '可用'
                ? 'list-car-state1'
                : item.state === '使用中'
                ? 'list-car-state2'
                : item.state === '被预约'
                ? 'list-car-state3'
                : item.state === '故障'
                ? 'list-car-state4'
                : '',
              !index && play ? 'toUp' : '',
            ]"
            v-for="(item, index) in listCars"
            :key="index"
          >
            <el-tooltip
              effect="dark"
              :content="item.name + ' ' + item.code + ' ' + item.state"
              placement="top"
              class="list-car-item"
            >
              <div class="flex width_100 justify-content-space-between">
                <div class="list-car-list list-car-name">
                  {{ item.name }}
                </div>
                <div class="list-car-list list-car-code">
                  {{ item.code }}
                </div>
                <div class="list-car-list list-car-state">
                  {{ item.state }}
                </div>
                <div class="list-car-list list-car-details" @click="getDetails(item.id)">查看</div>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </dv-border-box-8>
</template>

<script>
export default {
  props: {
    ListCarData: Object,
    listCar: Array,
    reginName:String,
    getSeachCar:Function
  },
  data() {
    return {
      play: false,
      input3: "",
      listCars: [],
      clearFlag:null,
    };
  },
  watch: {
    listCar: {
      handler(newVal, oldVal) {
        this.listCars = JSON.parse(JSON.stringify(newVal));
        this.getTimer();
        console.log('我被不停地调用了吗');
      },
      deep: true,
    },
  },

  mounted() {},
  methods: {
    getTimer() {
      clearInterval(this.clearFlag);
      if (this.listCars.length > 19) {
        this.clearFlag = setInterval(this.startPlay, 2000);
      }
    },
    handleSearch() {
      this.getSeachCar(this.input3)
      console.log(this.input3);
    },
    handleRefresh() {
      this.input3 = "";
      this.handleSearch()
    },
    startPlay() {
      let that = this;
      that.play = true; //开始播放
      setTimeout(() => {
        that.listCars.push(that.listCars[0]); //将第一条数据塞到最后一个
        that.listCars.shift(); //删除第一条数据
        that.play = false; //暂停播放,此处修改，保证每一次都会有动画显示
      }, 500);
    },
    getDetails(e) {
      this.$router.push({
        path: "/car/manage/details",
        query: {
          type: "details",
          id: e,
        },
      });
    }
  },
};
</script>


<style scoped>
.dv-border-box-8 {
  color: #fff;
  width: 260px;
  height: 550px;
}
.search-car-region ::v-deep .el-input__inner {
  height: 30px;
  background: none;
  border-right: none;
  color: rgb(245, 243, 243);
}
.search-car-region ::v-deep .el-input-group__append {
  background: none !important;
  text-align: center !important;
}
.search-car-region ::v-deep .el-input-group__append .el-button {
  background: none !important;
}
::v-deep .el-icon-refresh:hover {
  cursor: pointer;
}

::v-deep .el-input .el-input__inner {
  border-color: #dcdfe6 !important;
  padding: 0 5px !important;
}

::v-deep .el-input-group__append .el-input-group__prepend {
  padding: 0 10px !important;
}

.el-button {
  padding: 0;
}

.list-title {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}

.list-car-box {
  height: 430px;
  list-style: none;
  width: 100%;
  text-align: center;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.list-car {
  font-size: 0.6rem;
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

::v-deep .el-tooltip__popper .is-dark {
  opacity: 0.1;
}

.list-car:hover {
  cursor: pointer;
  color: #fff;
}

.list-car .list-car-list {
  width: 24.5%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.list-car .list-car-state {
  text-align: right;
}

.list-car .list-car-details {
  color: #02a7f0;
  text-align: right;
}

.list-car-state1 {
  color: #00ffff;
}
.list-car-state2 {
  color: #95f204;
}
.list-car-state3 {
  color: #f59a23;
}
.list-car-state4 {
  color: #d9001b;
}

.toUp {
  margin-top: -20px;
  transition: all 0.5s;
}
</style>