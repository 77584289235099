const currentYear = new Date().getFullYear(); // 获取当前年份
const fiveYearsAgo = currentYear - 4; // 计算五年前的年份

const years = [];
for (let i = fiveYearsAgo; i <= currentYear; i++) {
  years.push(i);
}
console.log(years); 
const zhexian = {
  xAxis: {
    type: 'category',
    // show: false,
    // data: ['2019', '2020', '2021', '2022', '2023'],
    data: [...years],
  },
  yAxis: {
    type: 'value',
  },

  tooltip: {
    trigger: 'item',
    formatter(item) {
      console.log('item', item)
      return `${item.name}年${item.seriesName}为: ${item.value}元`;
    },
  },

  legend: {
    orient: "horizontal",
    top: "0px",
    right: '20px',
    align: 'left',
    data: ['正常完成', '售后退款',],
    textStyle: {
      fontSize: 10,//字体大小
      color: '#fff'//字体颜色
    },
  },
  grid: {
    top: '15%',
    left: '0',
    right: '0',
    bottom: '5%',
    containLabel: true,
  },
  series: [
    {
      name: '正常完成',
      type: 'line',
      smooth: true,
      data: [550, 580, 630, 610, 660],
    },
    {
      name: '售后退款',
      type: 'line',
      smooth: true,
      data: [400, 440, 460, 520, 580],
    },
  ],
  color: ['#5AD7A6', '#FA493F',],
};

const zhuzhuang = {
  color: ['#298ADD', '#F59A23', '#BFF531', '#D9001B'],
  legend: {
    orient: "horizontal",
    top: "0px",
    right: '20px',
    align: 'left',
    itemWidth: 10,
    itemHeight: 10,
    data: ['可用', '被预约', '使用中', '故障'],
    textStyle: {
      fontSize: 10,//字体大小
      color: '#fff'//字体颜色
    }
  },
  grid: {
    top: '15%',
    left: '0%',
    right: '0',
    bottom: '5%',
    containLabel: true
  },
  xAxis: {
    splitLine: {
      show: false
    },
    type: 'category',
    axisLabel: {
      textStyle: {
        fontSize: 10,//字体大小
        color: 'rgba(255,255,255,0.65)'
      }
    },
    data: ['可用', '被预约', '使用中', '故障'],

  },
  yAxis: {
    splitLine: {
      show: false
    },
    type: 'value',
    axisLabel: {
      textStyle: {
        color: 'rgba(255,255,255,0.45)'
      }
    }
  },
  tooltip: {
    trigger: 'item',
    formatter(item) {
      return `${item.name}${item.value}辆`;
    },
  },
  series: [
    // {

    //   name: '可用',
    //   stack: '设备',
    //   type: 'bar',
    //   barWidth: 20,
    //   data: [28],
    //   itemStyle: {
    //     borderRadius: 4,
    //     color: {
    //       type: 'linear',
    //       x: 0,
    //       y: 0,
    //       x2: 0,
    //       y2: 1,
    //       colorStops: [{
    //         offset: 0, color: '#02F4C3',
    //       }, {

    //         offset: 1, color: '#312AAD',
    //       }],
    //       global: false,
    //     },
    //   }
    // },
    // {
    //   name: '被预约',
    //   type: 'bar',
    //   barWidth: 20,
    //   stack: '设备',
    //   data: [0, 27],
    //   itemStyle: {
    //     borderRadius: 4,
    //     color: {
    //       type: 'linear',
    //       x: 0,
    //       y: 0,
    //       x2: 0,
    //       y2: 1,
    //       colorStops: [{
    //         offset: 0, color: '#FACC8F',

    //       }, {
    //         offset: 1, color: '#F59A23',
    //       }],
    //       global: false,
    //     },
    //   }
    // },
    // {
    //   name: '使用中',
    //   type: 'bar',
    //   barWidth: 20,
    //   stack: '设备',
    //   data: [0, 0, 7],
    //   itemStyle: {
    //     borderRadius: 4,
    //     color: {
    //       type: 'linear',
    //       x: 0,
    //       y: 0,
    //       x2: 0,
    //       y2: 1,
    //       colorStops: [{
    //         offset: 0, color: '#CDF92F',
    //       }, {
    //         offset: 1, color: '#52903D',
    //       }],
    //       global: false,
    //     },
    //   }
    // },
    // {
    //   name: '故障',
    //   type: 'bar',
    //   barWidth: 20,
    //   stack: '设备',
    //   data: [0, 0, 0, 6],
    //   itemStyle: {
    //     borderRadius: 4,
    //     color: {
    //       type: 'linear',
    //       x: 0,
    //       y: 0,
    //       x2: 0,
    //       y2: 1,
    //       colorStops: [{
    //         offset: 0, color: '#F6B583',
    //       }, {
    //         offset: 1, color: '#D9001B',
    //       }],
    //       global: false,
    //     },
    //   }
    // }
  ]
}

export {
  zhexian,
  zhuzhuang,

};
