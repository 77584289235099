<template>
  <div class="width_100">
    <dv-border-box-8
      class="marginBottom10 dv-border-box-8"
      backgroundColor="#0A1C3C"
    >
      <div class="marginBottom10">区域信息-{{ reginName }}</div>
      <div >
        <img
        v-if="alldatas.allImg != ''"
        width="400px"
        height="180px"
        :src=alldatas.allImg
      >
      <img
        v-if="alldatas.allImg == ''"
        width="400px"
        height="180px"
        :src=alldatas.upCount.mapImage
      >
      </div>
      <!-- <div v-else>
        <img
        width="400px"
        height="180px"
        :src=alldatas.upCount.mapImage
      >
      </div> -->
    </dv-border-box-8>

    <dv-border-box-8
      class="marginBottom10 dv-border-box-8-1"
      backgroundColor="#0A1C3C"
    >
      <div class="marginBottom10">车辆状态-{{ reginName }}</div>
      <CarState :eZhuZhuang=alldatas.zhuzhuangData />
    </dv-border-box-8>

    <dv-border-box-8
      class="marginBottom10 dv-border-box-8-1"
      backgroundColor="#0A1C3C"
    >
      <div class="marginBottom10 flex justify-content-space-between">
        <div>
          订单金额(元)-{{ reginName }}
        </div>
        <div class="span">
          单位: 元
        </div>
      </div>
      <OrderPrice :eZheXian=alldatas.zhexianData />
    </dv-border-box-8>
  </div>
</template>

<script>
import CarState from "./carState.vue";
import OrderPrice from "./orderPrice.vue";
export default {
  components: {
    CarState,
    OrderPrice,
  },
  props:{
    alldatas:Object,
    reginName:String
  }
};
</script>

<style scoped>
.left-map {
  width: 420px;
}

.dv-border-box-8 {
  color: #fff;
  padding: 20px;
  width: 400px;
  height: 220px;
}

.dv-border-box-8-1 {
  color: #fff;
  padding: 20px;
  width: 400px;
  height: 320px;
}
</style>