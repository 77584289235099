<template>
  <div>
    <v-chart
      class="chart"
      :option={...yuanbing,series:carTypeEchart.PieCatTypeData}
    />
  </div>
</template>

<script>
import { yuanbing } from "./carTypeEchart";

export default {
  props:{
    carTypeEchart:Object
  },
  data() {
    return {
      yuanbing,
    };
  },
};
</script>

<style>
.chart {
  width: 100%;
  height: 250px;
}
</style>
