import { render, staticRenderFns } from "./carTypeOrder.vue?vue&type=template&id=241b1061&scoped=true&"
import script from "./carTypeOrder.vue?vue&type=script&lang=js&"
export * from "./carTypeOrder.vue?vue&type=script&lang=js&"
import style0 from "./carTypeOrder.vue?vue&type=style&index=0&id=241b1061&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241b1061",
  null
  
)

export default component.exports