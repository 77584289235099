<template>
  <dv-border-box-8
    :reverse="true"
    class="marginBottom10 dv-border-box-8"
    backgroundColor="#0A1C3C"
  >
    <div class="padding10">
      <div> 车型/订单率占比-{{ reginName }} </div>
      <div style="margin-top: 15px;">
        <CarTypeEcharts :carTypeEchart=pieData />
        <OrderEcharts :OrderChart=pieData />
      </div>
    </div>

  </dv-border-box-8>
</template>

<script>
import CarTypeEcharts from "./components/carTypeEcharts.vue";
import OrderEcharts from "./components/orderEcharts.vue";
export default {
  components: {
    CarTypeEcharts,
    OrderEcharts,
  },
  props:{
    pieData:Object,
    reginName:String
  },
};
</script>


<style scoped>
.dv-border-box-8 {
  color: #fff;
  width: 260px;
  height: 550px;
}
</style>