<template>
  <div class="home">
    <v-chart class="map" :option="mapOfChina" />
  </div>
</template>

<script>
import "echarts";
import "./china";

export default {
  props: {
    mapLists: Array,
    mapListNums: Array,
  },
  watch: {
    mapLists: {
      handler(newVal, oldVal) {
        console.log(this.mapListNums,'1q');
        if (this.mapLists) {
          if (newVal) {
            this.mapOfChina.series[1].data = newVal;
          } else {
            this.mapOfChina.series[1].data = oldVal;
          }
        }
      },
      deep: true, //对象内部属性的监听，关键。
    },
    mapListNums: {
      handler(newVal, oldVal) {
        console.log(this.mapLists,'2');
        if (this.mapListNums) {
          if (newVal) {
            this.mapOfChina.series[0].data = newVal;
          } else {
            this.mapOfChina.series[0].data = oldVal;
          }
        }
      },
      deep: true, //对象内部属性的监听，关键。
    },
  },
  data() {
    return {
      mapOfChina: {
        grid: {
          top: "0",
          left: "0",
          right: "0",
          containLabel: true,
        },
        roam: true,
        scaleLimit: {
          min: 1,
          max: 10,
        },
        geo: {
          map: "china",
          zoom: 1.2,
          itemStyle: {
            areaColor: "#0b122e",
            color: "red",
            borderColor: "#232652",
            borderWidth: 2,
          },
          emphasis: {
            itemStyle: {
              areaColor: "#1af9e5",
              color: "#fff",
            },
          },
        },
        tooltip: {
          trigger: "item",
          formatter(params) {
            let all;
            if (params.data) {
              all = params.data.all;
            } else {
              all = 0;
            }
            const htmlStr = `
          <div style='font-size:18px;'> ${params.name}</div>

        `;
            // <p style='text-align:left;margin-top:10px;'>
            //         区域数量：${all}<br/>
            //   </p>
            return htmlStr;
          },
        },
        labelLayout: function (params) {
          return {
            x: params.rect.x,
            moveOverlap: "shiftY",
          };
        },
        visualMap: {
          min: 0,
          max: 1000,
          left: 'left',
          top: 'bottom',
          text: ['数量'],           // 文本，默认为数值文本
          calculable: true,
          inRange: {
            color: ['#87cefa', '#ff6347']     // 颜色范围
          }
        },
        series: [
          {
            type: "map",
            mapType: 'china',
            geoIndex: 0,
            // itemStyle:{
            //     normal:{label:{show:true}},
            //     emphasis:{label:{show:true}}
            // },
            data: [
              // { name: "广东", value: 500 },
              // { name: "北京", value: 700 },
              // { name: "浙江", value: 300 },
              // { name: "四川", value: 900 },
              // { name: "辽宁", value: 1000 },
            ],
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: true,
              },
            },
            zlevel: 1,
            geoIndex: 0,
            name: "中国地图统计数据",
            map: "china",
            mapType: "自定义地图",
            selectedMode: "single",
            showLegendSymbol: false,
            smooth: true,
            aspectScale: 0.75,
            zoom: 1.2,
            roam: false,
            itemStyle: {
              normal: {
                color: "#ffffff", //散点的颜色
                shadowBlur: 10,
                shadowColor: 20,
                fontSize: "12px",
                borderWidth: 0.5,
                label: {
                  show: true,
                  fontSize: 14,
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
              emphasis: {
                areaColor: "#27599d",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 5,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.9)",
                label: {
                  fontSize: 20,
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            data: [
            // { name: "北京烤鸭", value: [116.46122, 39.97886, 9] },
            //   { name: "兰州拉面", value: [103.86615, 36.040129, 9] },
            //   { name: "新疆烤肉", value: [87.613228, 43.810394, 9] },
            //   { name: "长沙臭豆腐", value: [112.915204, 28.207735, 9] },
            //   { name: "西安肉夹馍", value: [108.953445, 34.288842, 9] },
            //   { name: "云南", value: [102.710002, 25.045806, 9] },
            ],
          },
        ],
      },
    };
  },
  methods: {},
  mounted() {
    console.log("mapOfChina", this.mapOfChina);
  },
};
</script>

<style scoped>
.map {
  height: 826px;
  width: 100%;
}
</style>
